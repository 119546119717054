import dynamic from 'next/dynamic';
import { productMinQuantity, productMaxQuantity } from 'config/basket';
import { priceColorsMap } from 'src/enums/priceColors';
import { sendMessageTargetsKeys } from 'src/enums/sendMessageTargets';
import useAddToBasketControls from 'src/hooks/useAddToBasketControls';
import useCurrentCurrency from 'src/hooks/useCurrentCurrency';
import useAddToBasket from 'src/hooks/useAddToBasket';
import useBasketParts from 'src/hooks/useBasketParts';
import BasketPartsSelection from '../BasketPartsSelection';

import useTranslation from 'src/hooks/useTranslation';
import useAnalytics from 'src/hooks/useAnalytics';
import * as S from './styles';

const defaultProps = {
  stock: null,
  onClick: () => {},
  pricePresentationType: 'normal',
  recommendationId: null,
  onBuy: () => null,
  priceWithSign: null,
  pricingValueVariants: [],
  largeImgUrl: null,
  priceSign: null,
  delayLargeImg: 0,
  fromCarousel: false,
  minHeight: 272,
  hideOnnTop: false,
};

export const ProductTile = ({
  name,
  readMoreLinkProps,
  imgUrl,
  largeImgUrl,
  largeImgShowDelay,
  priceWithSign,
  onClick,
  pricePresentationType: defaultPricePresentationType,
  stock,
  nowInBasket,
  isFullPackages,
  isPartial,
  productId,
  stepQuantityProduct,
  recommendationId,
  onBuy,
  categoryName,
  pricingValueVariants,
  priceSign,
  sku,
  producerName,
  onntop,
  onntopcb,
  onnTopHomePageLayout,
  onnTopHomePageSlug,
  userName,
  userEmail,
  showAskForm,
  delayLargeImg,
  minHeight,
  fromCarousel,
  hideOnnTop,
  variant,
  hideStockValue = false,
  fromListing = false,
  className,
  ...restProps
} = defaultProps) => {
  const isStockEmpty = stock === 0 || Number(stock?.value) === 0;
  const shouldDisplayStockInfo = !hideStockValue && stock != null;

  const transformPrice = useCurrentCurrency({ sign: priceSign });
  const nonZeroPricingValueVariants = pricingValueVariants.filter(({ price }) => price > 0);
  const firstNonZeroPricingValue = nonZeroPricingValueVariants.length > 0 ? nonZeroPricingValueVariants[0] : null;

  const priceForMinimalQuantity = !!firstNonZeroPricingValue && priceSign ? firstNonZeroPricingValue?.price : null;
  const priceVariant = priceColorsMap.get(firstNonZeroPricingValue?.customPriceColor) || defaultPricePresentationType;

  let priceToDisplay = null;
  let lastPriceMessage = '';
  if (priceWithSign) {
    priceToDisplay = priceWithSign;
  } else if (priceForMinimalQuantity && priceForMinimalQuantity > 0) {
    const priceObj = pricingValueVariants.find(({ price }) => price === priceForMinimalQuantity);
    lastPriceMessage = priceObj.lastPrice;
    priceToDisplay = transformPrice(priceForMinimalQuantity);
  }

  const crossedPriceObj = pricingValueVariants?.[0];

  const { t } = useTranslation();
  const askForProductAvailabilityLabel = t('askForProductAvailability');
  const updateBasketLabel = t('updateBasket');
  const addToBasketLabel = t('addToBasket');

  const { onAddToBasket, onRemoveFromBasket } = useAnalytics();

  const [addToBasket, { loading: addToBasketLoading }] = useAddToBasket({
    onCompleted: (data) => {
      onBuy(data);
      const product = {
        id: sku,
        name,
        category: categoryName,
        quantity: data?.addToBasket?.added?.quantity ?? 0, // TODO: no data about removed amount
        price: nonZeroPricingValueVariants[0].seoPrice,
        brand: producerName,
        sku,
      };
      if (data?.addToBasket?.added?.quantity) {
        const indexOfCurrentPriceForQuantity =
          nonZeroPricingValueVariants.findIndex((element) => element.amount > data?.addToBasket?.added?.quantity) - 1;
        const priceValidForQuantity =
          indexOfCurrentPriceForQuantity < 0
            ? nonZeroPricingValueVariants[nonZeroPricingValueVariants.length - 1]
            : nonZeroPricingValueVariants[indexOfCurrentPriceForQuantity];
        onAddToBasket({
          ...product,
          price: priceValidForQuantity?.seoPrice || null,
        });
      } else {
        onRemoveFromBasket(product);
      }
    },
  });

  const {
    isProductInBasket,
    isDraftEqualToBasket,
    isDraftEqualToZeroValue,
    currentBasketQuantity,
    setDraftBasketQuantity,
    handleProductAddToBasketButtonClick,
  } = useAddToBasketControls({
    nowInBasket,
    zeroValue: productMinQuantity,
    fullPackages: isFullPackages,
    zeroPriceValue: 0,
    isLoading: addToBasketLoading,
    isPartial,
    addToBasket,
    productId,
    step: stepQuantityProduct,
    recommendationId,
  });

  const [getBasketParts, { data: basketPartsData, loading: basketPartsLoading }] = useBasketParts({
    productId: productId,
    // TODO: Will be implemented when magazines info are added
    onlyLocal: false,
  });

  const FormProductAvailability = dynamic(() => import('../FormProductAvailability'), {
    // eslint-disable-next-line react/display-name
    loading: () => (
      <S.AskForProductAvailability variant="primary" invert disabled>
        {askForProductAvailabilityLabel}
      </S.AskForProductAvailability>
    ),
    ssr: false,
  });

  const addToBasketInputLabel = `${isProductInBasket ? updateBasketLabel : addToBasketLabel}: ${name}`;

  return (
    <BasketPartsSelection
      productName={name}
      productImgSrc={imgUrl}
      parts={basketPartsData?.basketParts?.items || []}
      message={basketPartsData?.basketParts?.message}
      basketPartsPriceSectionEntries={basketPartsData?.basketParts.productinfo}
      addToBasketInputLabel={addToBasketInputLabel}
      busy={addToBasketLoading || basketPartsLoading}
      onAddToBasket={(parts, closePartSelectionModal) =>
        handleProductAddToBasketButtonClick({
          parts,
          after: () => {
            if (isPartial) {
              closePartSelectionModal();
            }
          },
        })
      }
    >
      {(openBasketPartsSelection) => {
        const fetchAndOpenPartsSelection = async () => {
          await getBasketParts();
          openBasketPartsSelection();
        };

        return (
          <S.ProductTileWrapper {...restProps} className="productTileWrapper">
            <S.ProductTile minHeight={minHeight}>
              {hideOnnTop ? null : (
                <S.OnnTopMarkers
                  onnTopKey={onntop}
                  cashbackKey={onntopcb}
                  onnTopHomePageLayout={onnTopHomePageLayout}
                  onnTopHomePageSlug={onnTopHomePageSlug}
                />
              )}

              <S.Item {...readMoreLinkProps} onClick={onClick} variant="quaternary">
                {!!imgUrl && !!largeImgUrl ? (
                  <S.PreviewOfLargeImage title={name} altTxt={name} imgSrc={largeImgUrl} openDelay={1000}>
                    <S.ItemImg url={imgUrl} alt={name} width={100} height={100} loading="lazy" />
                  </S.PreviewOfLargeImage>
                ) : null}

                {!!imgUrl && !largeImgUrl ? (
                  <S.ItemImg url={imgUrl} alt={name} width={100} height={100} loading="lazy" />
                ) : null}

                <S.ItemNameWrapper>
                  <S.ItemName>{name}</S.ItemName>
                </S.ItemNameWrapper>
                <S.PriceWrapper fromCarousel={fromCarousel}>
                  <S.CrossedPrice
                    className="crossed-price"
                    fromCarousel={fromCarousel}
                    isVisible={!!crossedPriceObj?.crossedPrice}
                  >
                    <span>{transformPrice(crossedPriceObj?.crossedPrice)}</span>
                  </S.CrossedPrice>

                  {!!priceToDisplay && <S.ItemPrice type={priceVariant}>{priceToDisplay}</S.ItemPrice>}
                </S.PriceWrapper>

                <S.LastPriceInfo lastPrice={lastPriceMessage} separateLines />

                {shouldDisplayStockInfo && (
                  <S.ItemStock disableOpacity={isProductInBasket}>
                    {!isStockEmpty ? (
                      <>
                        <S.ProductAvailabilityGreenIndicator />
                        <span>
                          {stock.value} {stock.unit}
                        </span>
                      </>
                    ) : (
                      stock?.desc || t('partsOutOfStock')
                    )}
                  </S.ItemStock>
                )}
              </S.Item>

              <S.AddToBasketSection
                disableOpacity={isProductInBasket}
                fromCarousel={fromCarousel}
                shouldDisplayStockInfo={shouldDisplayStockInfo}
                fromListing={fromListing}
              >
                {!showAskForm ? (
                  <>
                    <S.AddToBasketInput
                      id={`${name}_${productId}`}
                      label={addToBasketInputLabel}
                      min={productMinQuantity}
                      max={productMaxQuantity}
                      step={stepQuantityProduct}
                      nowInBasket={currentBasketQuantity}
                      busy={addToBasketLoading}
                      variant="rounded"
                      small
                      showZeroPlaceholder={!isDraftEqualToBasket && isDraftEqualToZeroValue}
                      onUpdate={setDraftBasketQuantity}
                      onClickOverride={isPartial && currentBasketQuantity > 0 ? fetchAndOpenPartsSelection : null}
                      onEnterKeyDown={async () => {
                        const basketQueryResult = await handleProductAddToBasketButtonClick({
                          parts: null,
                        });

                        const shouldDisplayPartialCalc =
                          basketQueryResult?.data?.addToBasket?.added?.showpartcalculator || false;

                        if (shouldDisplayPartialCalc) {
                          await fetchAndOpenPartsSelection();
                        }
                      }}
                    />
                    <S.AddToBasketButton
                      isProductInBasket={isProductInBasket}
                      isDraftEqualToZeroValue={isDraftEqualToZeroValue}
                      draft={!isDraftEqualToBasket}
                      busy={addToBasketLoading}
                      isRounded
                      hideText
                      onAdd={async () => {
                        const basketQueryResult = await handleProductAddToBasketButtonClick({
                          parts: null,
                        });

                        const shouldDisplayPartialCalc =
                          basketQueryResult?.data?.addToBasket?.added?.showpartcalculator || false;

                        if (shouldDisplayPartialCalc) {
                          await fetchAndOpenPartsSelection();
                        }
                      }}
                    />
                  </>
                ) : (
                  <FormProductAvailability
                    productName={name}
                    productIndex={productId}
                    onninenIndex={sku}
                    userName={userName}
                    userEmail={userEmail}
                    source={sendMessageTargetsKeys.PRODUCT_AVAILABILITY_INQUIRY}
                  >
                    {(openProductAvailabilityModal) => (
                      <S.AskForProductAvailability variant="primary" invert onClick={openProductAvailabilityModal}>
                        {askForProductAvailabilityLabel}
                      </S.AskForProductAvailability>
                    )}
                  </FormProductAvailability>
                )}
              </S.AddToBasketSection>
            </S.ProductTile>
          </S.ProductTileWrapper>
        );
      }}
    </BasketPartsSelection>
  );
};

ProductTile.defaultProps = defaultProps;
