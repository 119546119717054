import styled from 'styled-components';
import { rem } from 'polished';

const getBorderTopVariant = (variant, theme) =>
  ({
    primary: theme.defaultPalette.monochrome[1],
    secondary: theme.defaultPalette.monochrome[0],
  }[variant]);

export const Hr = styled.hr`
  border: none;
  border-top: ${({ height, variant, theme }) => `${rem(height)} solid ${getBorderTopVariant(variant, theme)}`};
`;
