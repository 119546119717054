import * as S from './styles';

const defaultProps = {
  variant: 'primary',
  height: 1,
};

export const Hr = ({ variant, ...restProps } = defaultProps) => <S.Hr variant={variant} {...restProps} />;

Hr.defaultProps = defaultProps;
