import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { rem, em } from 'polished';
import ImportedAddToBasketInput from '../AddToBasketInput';
import ImportedAddToBasketButton from '../AddToBasketButton';
import ImportedOnnTopMarkers from '../OnnTopMarkers';
import Button from '../Button';
import Image from '../Image';
import Link from '../Link';
import _PreviewOfLargeImage from '../PreviewOfLargeImage';

import ImportedLastPriceInfo from 'src/components/LastPriceInfo';

import { breakpointsKeys } from 'src/enums/breakpoints';

const ITEM_NAME_LINES = 3;

export const Item = styled(Link)`
  & > .content {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  ${up(breakpointsKeys.TABLET)} {
    img {
      transform: scale(0.9);
    }

    &:hover {
      img {
        transform: scale(1);
      }
    }
  }
`;

export const ItemImg = styled(Image)`
  display: flex;
  align-items: center;
  flex-grow: 1;
  width: ${rem(100)};
  height: ${rem(100)};

  > img {
    ${(p) => p.theme.helpers.transition(['transform'])};
    animation: ${(p) => p.theme.animations.fadeIn} ${(p) => p.theme.transitions.durations[2]};
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
  }
`;

export const ItemNameWrapper = styled.div`
  max-width: ${em(185)};
  margin-top: ${rem(2)};
  width: 100%;
  height: ${ITEM_NAME_LINES * 1}em;
  overflow: hidden;

  ${up(breakpointsKeys.TABLET)} {
    height: ${ITEM_NAME_LINES * 1.25 + 0.1}em;
  }
`;

export const ItemName = styled.p`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  font-family: ${({ theme }) => theme.global.fontFamily.secondary};
  font-size: ${({ theme }) => theme.typography.fontSize.s1};
  ${up(breakpointsKeys.TABLET)} {
    font-size: unset;
  }
`;

export const ItemPrice = styled.div`
  color: ${(p) => p.theme.price.color[p.type] || p.theme.price.color.awarded};

  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  font-family: ${({ theme }) => theme.global.fontFamily.tertiary};
  ${up(breakpointsKeys.TABLET)} {
    font-size: ${rem(16)};
  }
`;

export const ItemStock = styled.div`
  font-size: ${rem(12)};
  margin: ${rem(4)} 0 ${rem(5)};
  display: flex;
  transition: opacity 0.3s;
  color: ${({ theme }) => theme.defaultPalette.monochrome[4]};
  ${up(breakpointsKeys.DESKTOP)} {
    ${({ disableOpacity }) => !disableOpacity && 'opacity: 0;'}
  }
`;

export const OnnTopMarkers = styled(ImportedOnnTopMarkers)`
  position: absolute;
  z-index: 1;
`;

export const AddToBasketInput = styled(ImportedAddToBasketInput)`
  gap: 0;
  .inputBox {
    height: ${rem(26)};
  }
  > button {
    height: ${rem(25)};
    min-height: ${rem(25)};
    width: ${rem(25)};
    min-width: ${rem(25)};
    background-color: ${({ theme }) => theme.defaultPalette.monochrome[1]};
  }
  .minus {
    width: ${rem(26)};
    height: ${rem(26)};
    margin-right: ${rem(-6)};
  }
  .plus {
    width: ${rem(26)};
    height: ${rem(26)};

    margin-left: ${rem(-6)};
  }
  & .minus:hover,
  .plus:hover {
    background-color: ${({ theme }) => theme.defaultPalette.teal[0]};
    color: ${({ theme }) => theme.defaultPalette.monochrome[0]};
  }

  & .input {
    font-size: ${({ theme }) => theme.typography.size.s2};
    padding: ${em(4)} ${em(8)};
    min-width: 40px;
  }
`;

export const AddToBasketButton = styled(ImportedAddToBasketButton)`
  height: ${rem(25)};
  font-size: ${({ theme }) => theme.typography.fontSize.s2};
  padding: 0;
  max-width: ${rem(56)};
  flex-grow: 1;
`;

export const AddToBasketSection = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding-bottom: ${rem(4)};

  > * + * {
    margin-left: 0.75em;
  }
  ${up(breakpointsKeys.DESKTOP)} {
    ${({ disableOpacity }) => !disableOpacity && 'opacity: 0;'}
    transition: opacity 0.3s;

    .add-to-basket-tooltip {
      display: flex;
      min-width: ${rem(56)};
    }
    position: static;
  }
`;

export const ProductTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 100%;
  border-radius: 15px;
`;

export const ProductTile = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  font-size: 14px;

  ${up(breakpointsKeys.DESKTOP)} {
    min-height: ${({ minHeight }) => rem(minHeight)};
    &:hover {
      ${ItemStock} {
        opacity: 1;
      }

      ${AddToBasketSection} {
        opacity: 1;
      }
    }
  }
`;

export const AskForProductAvailability = styled(Button)`
  text-transform: lowercase;
`;

export const LastPricesInfoWrapper = styled.div`
  text-align: center;
  color: ${(p) => p.theme.productCardSummary.color.lastPricesInfo};
  font-size: 9px;
  min-height: ${em(20)};
  ${up(breakpointsKeys.TABLET)} {
    font-size: 11px;
  }
`;

export const CrossedPrice = styled.div`
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  text-decoration: line-through;
  color: ${({ theme }) => theme.price.color.normal};
  white-space: nowrap;
  align-self: flex-start;
  font-size: ${({ theme }) => theme.typography.fontSize.s1};

  ${({ fromCarousel }) => !fromCarousel && 'margin-bottom: 3px'}
  ${up(breakpointsKeys.TABLET)} {
    ${({ theme, fromCarousel }) =>
      fromCarousel
        ? theme.helpers.dynamicFontSize(theme.typography.sizesSet.r6)
        : theme.helpers.dynamicFontSize(theme.productPricesList.fontSize.crossedPrice)};
  }
`;

const createStylesForFromCarouselPriceWrapper = () => css`
  ${up(breakpointsKeys.TABLET)} {
    flex-direction: row;
    align-items: baseline;
  }
`;

export const PriceWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${rem(6)};

  ${({ fromCarousel }) => fromCarousel && createStylesForFromCarouselPriceWrapper()};
`;

export const LastPriceInfo = styled(ImportedLastPriceInfo)`
  gap: ${em(4)};

  width: 100%;

  min-height: ${({ theme }) => theme.productCardSummary.fontSize.lastPricesInfo};
  ${up(breakpointsKeys.DESKTOP)} {
    > div {
      margin-top: unset;
    }
  }
`;

export const PreviewOfLargeImage = styled(_PreviewOfLargeImage)`
  /* position: relative;
  left: -50%;
  transform: translateX(50%); */
`;

export const ProductAvailabilityGreenIndicator = styled.span`
  &:before {
    content: ' ';
    display: inline-block;
    width: ${em(12)};
    margin-right: ${rem(5)};
    height: ${em(12)};
    border-radius: 50%;

    border: 1px solid ${({ theme }) => theme.defaultPalette.chateauGreen[0]};
    background-color: ${({ theme }) => theme.defaultPalette.chateauGreen[0]};

    ${up(breakpointsKeys.TABLET)} {
      width: ${em(16)};
      height: ${em(16)};
      margin-top: 0;
    }
  }
`;
